import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import DonutSmallTwoToneIcon from '@mui/icons-material/DonutSmallTwoTone';
import HandymanTwoToneIcon from '@mui/icons-material/HandymanTwoTone';
import BuildCircleTwoToneIcon from '@mui/icons-material/BuildCircleTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TodayIcon from '@mui/icons-material/Today';

import {CircularProgress} from '@mui/material';

import DonutChart from "../Components/DonutChart/DonutChart.js";
import Dropdown from "../Components/Dropdown.js";
import PercentageBar from '../Components/PercentageBar.js';

function Dashboard(props){
    const session = props?.session;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const individualData = session?.individual?.data[currentAccountID];
    const dashboardData = session?.dashboard?.data;
    const userFunctions = session?.user?.functions;
    const currentPage = session?.user?.data?.currentActivity;
    const viewType = session?.env?.viewport?.viewType;
    const reporting = session?.reporting;
    const updateReporting = reporting?.setData;
    const reportingData = reporting?.data;
    const selectedTermData = dashboardData?.selectedTerm;
    const allReports = reportingData[currentAccountID]?.allReports;
    const accountData = session?.account?.data[currentAccountID];
    const accountSummaryData = accountData?.summaryData;

    const [deceasedIndividuals, setDeceasedIndividuals] = useState(undefined);
    const [downloadStatus, setDownloadStatus] = useState(undefined);
    const [selectedGroup, setSelectedGroup] = useState({name : dashboardData?.selectedGroup, showMenu : false});
    const [frequencyReportList, setFrequencyReportList] = useState(undefined);
    const [hoveredFrequencyRow, setHoveredFrequencyRow] = useState(0); // Default to first row

    const currentMonthName = (() => {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return monthNames[new Date().getMonth()];
    })();

    const hasNotifications = !!Object.keys(session?.notifications?.data || {}).length;

    const navigate = useNavigate();
    const currentPath = window.location.pathname.replace(/\//g, '');

    const selectedReport = reporting?.data[currentAccountID]?.selectedReport;
    const newReportTemplate = reporting?.data[currentAccountID]?.reset;
    const currentlyEditing = !selectedReport?.recordID && JSON.stringify(selectedReport) !== JSON.stringify(newReportTemplate);

    const systemReportPointer = {
        "MTD" : 253,
        "YTD" : 254,
        "allTime" : 251,
        "allIndividuals" : 109,
        "allDeceased" : 251,
        "Weekly" : -2,
        "Daily" : -3,
    }
    function reportByRecordID(recordID) {
        return allReports.find(report => report?.recordID === recordID);
    }
    
    function selectSystemReport(e, report){
        const currentStorage = JSON.parse(localStorage.getItem("storage")) || {};
        const filteredResults = reporting?.functions?.filterItemsWithQuery(report?.query, deceasedIndividuals);
        e.stopPropagation();
        if (currentlyEditing) {
            session?.env?.setOverlay("reportErrorHandler");     
            updateReporting(`${session?.handler?.data?.currentAccountID}`, 'tempReport', report);
        } else if (report?.recordID !== selectedReport?.recordID) {
            if (unsavedChanges(true)) {
                session?.env?.setOverlay("reportErrorHandler");
                updateReporting(`${session?.handler?.data?.currentAccountID}`, 'tempReport', report);
            } else {
                reporting?.functions?.toggleAllSelectRows(filteredResults, selectedReport?.stem, false, session);
                updateReporting(`${session?.handler?.data?.currentAccountID}`, 'selectedReport', report);
            }
        } else {
            reporting?.functions?.toggleAllSelectRows(filteredResults, selectedReport?.stem, false, session);
            updateReporting(`${session?.handler?.data?.currentAccountID}`, 'selectedReport', selectedReport);
        }

        if (reporting?.functions?.reportType(report?.stem) === "generated" && selectedReport?.recordID !== report?.recordID) {
            reporting?.functions?.requestResultBlock(
                { newQuery: report?.query, newColumns: report?.columns, newOffset : 0, newLimit : currentStorage?.reporting?.rowMax ?? 100 },
                {session},
                session?.handler?.data?.currentAccountID
            );
        }

        // if (viewType === "mobile") {
        //     setReportingView("report");
        // }
    }

    const unsavedChanges = (reportID) => {
        if (!selectedReport || Object.keys(reporting?.data[currentAccountID]?.allReports)?.length === 0) return false;

        const index = reporting?.data[currentAccountID]?.allReports?.findIndex(
            (report) => report.recordID === selectedReport.recordID
        );
        if (index === -1) return false;

        if(reportID === true){
            return !isEqualWithoutAttributes(
                // reporting?.data?.allReports[index], selectedReport, ["list", "totalFound", "lastRecordID", "lastQuery", "lastPageIndex"]
                reporting?.data[currentAccountID]?.allReports[index], selectedReport, ["list", "totalFound", "lastRecordID", "lastPageIndex", "referenceBranch", "referenceStem","referenceRecordID","groupColumns","subReport","sortedListResults"]
            );
        }else if (selectedReport.recordID) {
            const index = reporting?.data[currentAccountID]?.allReports.findIndex(
                (report) => report.recordID === selectedReport.recordID
            );
            return !isEqualWithoutAttributes(
                // reporting?.data?.allReports[index], selectedReport, ["list", "totalFound", "lastRecordID", "lastQuery", "lastPageIndex"]
                reporting?.data[currentAccountID]?.allReports[index], selectedReport, ["list", "totalFound", "lastRecordID", "lastPageIndex", "referenceBranch", "referenceStem","referenceRecordID","groupColumns","subReport","sortedListResults"]
            ) && selectedReport?.recordID === reportID;
        }
    };

    const isEqualWithoutAttributes = (objA, objB, attributesToIgnore = []) => {
        const deepEqual = (a, b) => {
            if (typeof a !== typeof b) {
                return false;
            }
    
            if (typeof a !== 'object') {
                return a === b;
            }
    
            if (Array.isArray(a) && Array.isArray(b)) {
                if (a.length !== b.length) {
                    return false;
                }
                for (let i = 0; i < a.length; i++) {
                    if (!deepEqual(a[i], b[i])) {
                        return false;
                    }
                }
                return true;
            }
    
            const keysA = Object?.keys(a ?? {})?.filter((key) => !attributesToIgnore.includes(key));
            const keysB = Object?.keys(b ?? {})?.filter((key) => !attributesToIgnore.includes(key));
    
            if (keysA.length !== keysB.length) {
                return false;
            }
    
            for (const key of keysA) {
                if (!deepEqual(a[key], b[key])) {
                    return false;
                }
            }
    
            return true;
        };
    
        return deepEqual(objA, objB);
    };

    const currentYear = new Date().getFullYear();

    function toggleOverlay(overlay){
        session?.env?.setOverlay(session?.env?.overlay === overlay ? undefined : overlay);
    }

    function DataLink({ className, onClick, headerText, count, description, term }) {
        const [isHovered, setIsHovered] = useState(false);

        return (
            <div
                className={`data ${className} f cC bR g fC p dP${selectedTermData?.term === term ? " active" : ''}${!deceasedIndividuals ? " idle" : ''}`}
                onClick={deceasedIndividuals ? onClick : null}
                onMouseOver={(e) => setIsHovered(true)}
                onMouseLeave={(e) => setIsHovered(false)}
            >
                <div className="header f g cC fR lH dG">
                    <div className="f cL s gCW">
                        {headerText}
                    </div>
                    {isHovered ? 
                        <Link
                            className="viewMore f cC bold bR e g"
                            to={"/reporting"}
                            onClick={(e)=>{
                                
                                selectSystemReport(e, reportByRecordID(systemReportPointer[term]));
                                // showSummaryReport(term)}
                            }}
                        >
                            <AssessmentTwoToneIcon/>
                            <div className="f cR bold lH gCW">
                                View Report
                            </div>
                        </Link>
                    :
                        <div className="f cR bold lH gCW">
                            <div>
                                {term === "allTime" ? "All Time" : term}
                            </div>
                        </div>
                    }

                </div>
                <div className="divider"></div>
                <div className="f cC g">
                    <div className="count bold f cC">
                        {count.toLocaleString() ?? "-"}
                    </div>
                    <div className="f cC gCW">
                        {description}
                    </div>
                </div>
            </div>
        );
    }

    function countReportsByGroupID(targetGroupID) {
        let count = 0;
        allReports?.forEach(report => {
            if (report.groupID && report.groupID.value === targetGroupID && report?.relatedAccountID == currentAccountID) {
                count++;
            }
        });
        return count;
    }

    function printSummaryData(category){
        const rowData = {
            individualCount : {
                prompt : "Individuals on Record",
                point : accountSummaryData?.totalIndividuals?.toLocaleString() ?? 0,
                icon : <PeopleAltIcon/>,
                onClick : (e)=>{
                    selectSystemReport(e, reportByRecordID(systemReportPointer["allIndividuals"]));
                },
                path : "/reporting",
            },
            frequencyReportCount : {
                prompt : "Reported Cycles",
                //point : countReportsByGroupID(systemReportPointer[accountData?.reportFrequency])?.toLocaleString() ?? 0,
                point : countReportsByGroupID(systemReportPointer[accountData?.numWeeksReported])?.toLocaleString() ?? 0,
                icon : <RecentActorsIcon/>,
            },
            nextReport : {
                prompt : "Next Report Date",
                point : session?.env?.functions?.reformatDate(accountSummaryData?.nextReportDate) ?? "N/A",
                icon : <EventRepeatIcon/>,
            },
            averageMonthlyDeaths : {
                prompt : "Average Deaths Per Month",
                point : Math.round(accountSummaryData?.avgDeathsFoundPerMonth)?.toLocaleString() ?? 0,
                icon : <BarChartIcon/>,
            }
        }

        const currentCategory = rowData?.[category];
        const content = (
            <>
                <div className="f cC">
                    {currentCategory?.icon}
                </div>
                <div className="f cL bold prompt oH">
                    <div className="gCW">
                        {currentCategory?.prompt}
                    </div>
                </div>
                <div className="f cL bold lH">
                    {currentCategory?.point}
                </div>
            </>
        );
    
        if (currentCategory?.path) {
            return (
                <Link
                    to={currentCategory.path}
                    className={`row${currentCategory?.onClick ? " btn" : ''} cC g dG fC f bR s e`}
                    title={currentCategory?.prompt}
                    onClick={currentCategory?.onClick}
                >
                    {content}
                </Link>
            );
        } else {
            return (
                <div
                    className="row cC g dG fC f bR s e"
                    title={currentCategory?.prompt}
                    onClick={currentCategory?.onClick}
                >
                    {content}
                </div>
            );
        }
    }

    function showSelectedTerm(term, providedStartDate, providedEndDate){
        const termSchema = {...dashboardData?.reset};
        const currentYear = new Date().getFullYear();
        const dateHandler = {
            startDate : undefined,
            endDate : undefined
        }

        termSchema.branch = "deathTrac";
        termSchema.stem = "deceased";

        if (term === "MTD") {
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1;
            const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
            const monthString = currentDate.toLocaleString('default', { month: 'long' });
            const startDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;
            const endDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDayOfMonth}`;

            dateHandler.startDate = startDate;
            dateHandler.endDate = endDate;

            termSchema.startDate = startDate;
            termSchema.endDate = endDate;
        } else if (term === "YTD") {
            const startDate = `${currentYear}-01-01`;
            const endDate = `${currentYear}-12-31`;
            dateHandler.startDate = startDate;
            dateHandler.endDate = endDate;

            termSchema.startDate = startDate;
            termSchema.endDate = endDate;
        } else if (term === "allTime"){
            const currentDate = new Date();
            const endDate = currentDate.toISOString().split('T')[0];
            // const startDate = `${currentYear}-01-01`;
            const startDate = accountData?.dateCreated;
            dateHandler.startDate = startDate;
            dateHandler.endDate = endDate;

            termSchema.startDate = startDate;
            termSchema.endDate = endDate;
        }else if (term === "frequency"){
            // const currentDate = new Date();
            const startDate = providedStartDate ?? undefined;
            const endDate = providedEndDate ?? undefined;
            dateHandler.startDate = startDate;
            dateHandler.endDate = endDate;

            termSchema.startDate = startDate;
            termSchema.endDate = endDate;
        }

        termSchema.term = term;

        const deceasedData = session?.individual?.functions?.calculateTermData(deceasedIndividuals, dateHandler?.startDate, dateHandler?.endDate, dashboardData?.selectedGroup);
        // session?.reporting?.setData("deceased", "termDeaths", deceasedData);
        
        session?.reporting?.setData(`${session?.handler?.data?.currentAccountID}.deceased`, "termDeaths", deceasedData);
        props?.session?.dashboard?.setData("selectedTerm", null, termSchema);

    }


    const handleRowHover = (index) => {
        setHoveredFrequencyRow(index);
    };

    function FrequencyReportRow({ report, reports, index, downloadStatus, setDownloadStatus, showSelectedTerm, isHovered}) {
        const filteredResults = reporting?.functions?.filterItemsWithQuery(report?.query, deceasedIndividuals);
        const lastFilteredResults = index < (reports?.length - 1) ? reporting?.functions?.filterItemsWithQuery(reports?.[index + 1]?.query, deceasedIndividuals) : 0;
        const deathCount = filteredResults?.length;
        const lastDeathCount = index < reports?.length ? lastFilteredResults?.length : 0;

        if(report?.relatedAccountID == currentAccountID){
            return (
                <div
                    key={index}
                    className={`row g f cC p bR lH dG${
                        selectedTermData?.term === "frequency" && selectedTermData?.startDate === report?.details?.startDate ? " active" : ''}${
                            isHovered ? " hovering" : ''}${!deceasedIndividuals ? " idle" : ''}`}
                    onClick={deceasedIndividuals ? (e)=>{showSelectedTerm("frequency", report?.details?.startDate, report?.details?.endDate)} : null}
                    onMouseOver={() => handleRowHover(index)}
                >
                    <div className="f s cL gCW">
                        {`${filteredResults?.length?.toLocaleString() ?? 0} Death${deathCount === 1 ? '' : "s"}`}
                    </div>
                    {isHovered && (
                        <>
                        {session?.user?.data?.permissions?.includes("download") ?
                            <div className="f cC g dG">    
                                    <div className="f cR">
                                        <div
                                            className={`viewMore f g cC bR gCW p${
                                                downloadStatus?.[report?.details?.name] ? " active" : ''}${
                                                    downloadStatus?.[report?.details?.name] === "failed" ? " failed" : ''}`
                                                }
                                            onClick={
                                                (deceasedIndividuals && allReports) ? (e) => {
                                                    e.stopPropagation();
                                                    const name = report?.details?.name;

                                                    session?.reporting?.data[currentAccountID]?.downloadDateType == 'YYYY-MM-DD' ? 
                                                        setDownloadStatus((prevStatus) => ({
                                                            ...prevStatus,
                                                            [name]: reporting?.functions?.downloadDashReport(report, filteredResults),
                                                        }))
                                                        : 
                                                        setDownloadStatus((prevStatus) => ({
                                                            ...prevStatus,
                                                            [name]: reporting?.functions?.downloadDashReport(report, filteredResults, true),
                                                        }));
                                            } : null}
                                        >
                                            {downloadStatus?.[report?.details?.name] === true ? (
                                                <div className="downloading bold s e cC">
                                                    Downloading
                                                </div>
                                            ) : downloadStatus?.[report?.details?.name] === "failed" && !isHovered ? (
                                                <>
                                                    <ErrorTwoToneIcon/>
                                                    <div className="failed bold s e cC">
                                                        Failed to download
                                                    </div>
                                                </>
                                            ) : downloadStatus?.[report?.details?.name] === "failed" ? (
                                                <>
                                                    <ReplayTwoToneIcon />
                                                    <div className="bold e cC">
                                                        Retry Download
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <FileDownloadTwoToneIcon />
                                                    <div className="bold e cC">
                                                        Download
                                                    </div>
                                                </>                             
                                            )}
                                        </div>
                                    </div>
                                
                                <div className="f cC">
                                    <Link
                                        className="viewMore f g cC bR bold e gCW"
                                        to={"/reporting"}
                                        onClick={(e)=>{
                                            selectSystemReport(e, report);
                                        }}
                                    >
                                        <AssessmentTwoToneIcon/>
                                        View Report
                                    </Link>
                                </div>
                            </div>
                            :
                            <div className="f cC">
                                    <Link
                                        className="viewMore f g cC bR bold e gCW"
                                        to={"/reporting"}
                                        onClick={(e)=>{
                                            selectSystemReport(e, report);
                                        }}
                                    >
                                        <AssessmentTwoToneIcon/>
                                        View Report
                                    </Link> 
                                </div>
                        }
                        </>  
                    )}
                    <div className="f cR gC3 e gCW">
                        {report?.details?.name}
                    </div>
                </div>
            )
        }
        
    }

    function loadFrequencyReport(){
        const frequencyReports = allReports
        ?.filter((report) => {
            return report?.scheduled === true; //report.frequency === accountData?.reportFrequency && 
        })
        .sort((a, b) => new Date(b.details.endDate) - new Date(a.details.endDate));
        const generatedFrequencyReport = frequencyReports.map((report, index) => (
            <FrequencyReportRow
                key={index}
                report={report}
                reports={frequencyReports}
                index={index}
                downloadStatus={downloadStatus}
                setDownloadStatus={setDownloadStatus}
                showSelectedTerm={showSelectedTerm}
                isHovered={hoveredFrequencyRow === index}
            />
        ));

        setFrequencyReportList(generatedFrequencyReport);
    }

    function printGroupsList() {
        // Start with "All Groups" and append group names dynamically from individualData.groups
        const dynamicGroups = ["All Groups", ...individualData?.groups?.map(group => group.name).sort()];
        
        const options = dynamicGroups.map((groupName) => {
            // Find the group object that matches the groupName
            const groupObj = individualData?.groups?.find(group => group.name === groupName);
    
            return (
                <div
                    key={groupName} // Add a unique key
                    className={`option cC bR dP p g bold`}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedGroup((prev) => ({
                            ...prev,
                            name: groupName,
                            showMenu: !prev.showMenu,
                        }));
                        props?.session?.dashboard?.setData("selectedGroup", null, groupName);
                    }}
                >
                    <div className="f cL gCW">
                        {groupName === "All Groups" ? "All Groups" : (groupName + " (" + (groupObj?.count).toLocaleString() + ")")}
                    </div>
                </div>
            );
        });
    
        return options;
    }

    function displayTermTitle(){
        let term =  dashboardData?.selectedTerm?.term;
        if(dashboardData?.selectedTerm?.term === "allTime"){
            term = "All Time"
        }

        if(dashboardData?.selectedTerm?.term === "frequency"){
            term = session?.env?.functions?.reformatDate(dashboardData?.selectedTerm?.startDate) +
            " - " +
            session?.env?.functions?.reformatDate(dashboardData?.selectedTerm?.endDate)
        }

        return( term )
    }

    useEffect(() => {
        displayTermTitle()
    }, [session?.dashboard?.data?.selectedTerm]);

    useEffect(() => {
        if(allReports){
            loadFrequencyReport();
        }
    }, [downloadStatus, deceasedIndividuals, selectedTermData, allReports, hoveredFrequencyRow]);

    useEffect(() => {
        if (!session?.user?.data?.currentActivity) {
            userFunctions?.updateUserData(["currentActivity"], [currentPath] || ["dashboard"]);
        }
    }, [session?.user?.data, currentPath]);

    useEffect(() => {
        const validPaths = ["dashboard", "reporting", "upload", "researchHub"];

        if(currentPath && validPaths.includes(currentPath) && session?.user?.data?.currentActivity !== currentPath){
           userFunctions?.updateUserData(["currentActivity"], [currentPath] || ["dashboard"]);
        }else if(currentPath === "unsubscribe"){
            toggleOverlay("emailPreferences");
        }
    }, [currentPath]);

    useEffect(() => {
        if(deceasedIndividuals){
            const deceasedData = session?.individual?.functions?.calculateTermData(
                deceasedIndividuals,
                dashboardData?.selectedTerm?.startDate,
                dashboardData?.selectedTerm?.endDate,
                dashboardData?.selectedGroup);
    
            // session?.reporting?.setData("deceased", "termDeaths", deceasedData);
            // `${session?.handler?.data?.currentAccountID}.deceased`
            session?.reporting?.setData(`${session?.handler?.data?.currentAccountID}.deceased`, "termDeaths", deceasedData);
        }
    }, [dashboardData?.selectedGroup]);

    useEffect(()=>{
        if(props?.session?.individual?.data[currentAccountID]?.deathTrac?.deceased){
            const tempDeceasedIndividuals = [...props?.session?.individual?.data[currentAccountID]?.deathTrac?.deceased];
            setDeceasedIndividuals(tempDeceasedIndividuals);
        }
    }, [props?.session?.individual?.data[currentAccountID]?.deathTrac?.deceased]);

    useEffect(()=>{
        const validPaths = ["dashboard", "reporting", "upload", "researchHub", "unsubscribe"];

        if(!currentPath || !validPaths.includes(currentPath)){
            navigate("/dashboard");
            userFunctions?.updateUserData(["currentActivity"], ["dashboard"]);
        }

        if(currentPath === "unsubscribe"){
            toggleOverlay("emailPreferences");
            if(viewType === "mobile"){
                navigate("/dashboard");
            }else{
                navigate("/dashboard");
            }
            window.history.replaceState(null, '', '/dashboard');
        }
    }, []);

    return (
        <div className="dashboardHub g fR oH f g" onClick={()=>{ setSelectedGroup((prev) => ({ ...prev, showMenu: false }));}}>
            <div className="left panel g">
                <div className="top section bR g cC fC oH">
                    <div className="header bold g f">
                        <div className="f cC gC2 t">
                            Summary
                        </div>
                        <div className="f cC t e">
                            <Dropdown
                                setShowMenu={(input) => {
                                    setSelectedGroup((prev) => ({
                                        ...prev,
                                        showMenu: input ?? !prev.showMenu,
                                    }));
                                }}
                                showMenu={selectedGroup?.showMenu}
                                default="All Groups"
                                inactive={!accountData?.existingGroups}
                                generatedList={accountData?.existingGroups}
                                valuePointer="name"
                                onClick={(value) => {
                                    props?.session?.dashboard?.setData("selectedGroup", null, value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="f oA">
                        {accountSummaryData?.totalIndividuals === 0 ?
                            <div className="newAccountBlock g f dG dP fC">
                                <div className="placeholder g bR fR fC f cC dP">
                                    <div className="block g cC bold dG">
                                        <div className="prompt g dG">
                                            <div className="f cL">
                                                We have no individual data for your account. Please upload individuals to get your reporting cycle started.
                                            </div>
                                        </div>
                                        <div className="action f g cC">
                                            <div className="icon f cC">
                                                <BuildCircleTwoToneIcon/>
                                            </div>
                                            <div
                                                className="f cC g"
                                            >
                                                <Link
                                                    className="btn default dG g cC bR p dP"
                                                    to="/upload"
                                                >
                                                    Add New Individuals
                                                    <GroupAddTwoToneIcon/>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="prompt g dG">
                                            <div className="subPrompt f cC thin">
                                                <span>
                                                    For any questions, give us a call at:<br /><span className="bold">321 475-8251</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            <div className="details g f dG dP">
                                {frequencyReportList?.length === 0 ?
                                    <div className="placeholder g bR fR fC f cC dP">
                                        <div className="block g cC bold dG">
                                            <HandymanTwoToneIcon/>
                                            <div className="prompt g dG">
                                                <div className="f cL">
                                                    {`We're currently working on gathering your first ${accountData?.reportFrequency?.toLowerCase()} report.`}
                                                </div>
                                                <div className="prompt g dG">
                                                    <div className="subPrompt f cC thin">
                                                        <span>
                                                            For any questions, give us a call at:<br /><span className="bold">321 475-8251</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                <div className="aggregateData g f dG bR">
                                    {["MTD", "YTD", "allTime"].map((term) => (
                                        <DataLink
                                            key={term}
                                            className={term}
                                            onClick={() => showSelectedTerm(term)}
                                            headerText={
                                                term === "allTime"
                                                    ? `${new Date(accountData?.dateCreated).getFullYear()} - Today`
                                                    : term === "YTD"
                                                    ? currentYear
                                                    : currentMonthName
                                            }
                                            count={(term === "MTD" ? reportingData[currentAccountID]?.deceased?.reportedDeaths?.currentMonthCount : (term === "YTD" ? reportingData[currentAccountID]?.deceased?.reportedDeaths?.currentYearCount : reportingData[currentAccountID]?.deceased?.reportedDeaths?.totalCount)) || "-"}
                                            description="Verified Deaths"
                                            term={term}
                                        />
                                    ))}
                                </div>
                                }

                                <div className="breakdown tL f fR dG oH g">
                                    <div className="data f">
                                        <div className="block f g fC oA dG">
                                            {/* {printSummaryData("individualCount")} */}
                                            {printSummaryData("averageMonthlyDeaths")}
                                            {printSummaryData("frequencyReportCount")}
                                            {printSummaryData("nextReport")}
                                        </div>
                                    </div>
                                    <div className="visuals f">
                                        <div className="block f g fC oA dG">
                                            <PercentageBar
                                                rowData={
                                                    {
                                                        meanAgeOfLiving : {
                                                            prompt : "Average Age of Individual",
                                                            dataSet : accountSummaryData,
                                                            directValue : parseFloat(accountSummaryData?.avgIndividualAge?.toFixed(1)),
                                                            directMin : accountSummaryData?.minIndividualAge,
                                                            directMax : accountSummaryData?.maxIndividualAge,
                                                            idle : !deceasedIndividuals,
                                                        },
                                                        medianAgeOfLiving : {
                                                            prompt : "Average Age of Death",
                                                            dataSet : reportingData[currentAccountID]?.deceased?.termDeaths,
                                                            value : "mean",
                                                            idle : !deceasedIndividuals,
                                                        },
                                                        // modeAgeOfLiving : {
                                                        //     prompt : "Mode Age of Death",
                                                        //     dataSet : reportingData?.deceased?.termDeaths,
                                                        //     value : "modes",
                                                        //     n : 0,
                                                        //     idle : !deceasedIndividuals,
                                                        // },
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={`bottom section bR g cC fC oH${!reportingData[currentAccountID]?.deceased ? " dP" : ''}`}>
                    <div className="header f cC g lH fR">
                        <div className="f cL">
                            <div className="filters cR bR g s e dG lH">
                                <div className="f g cC">
                                    <div className="gCW" title={displayTermTitle()}>
                                        {displayTermTitle()}
                                    </div>
                                </div>
                                <div className="f cC">
                                    |
                                </div>
                                <div className="f g cC" title={dashboardData?.selectedGroup}>
                                    <div className="gCW">
                                        {dashboardData?.selectedGroup}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bold f cC gC2">
                            Source Graph
                        </div>
                    </div>
                    {!reportingData[currentAccountID]?.deceased ?
                            <div className="content f cC bR oH">
                                <div className="f loading cC gR2">
                                    <div className="cC f">
                                        <div className="g dG cC">
                                            <CircularProgress color="inherit"/>
                                            <div className="dG cC f">
                                                <DonutSmallTwoToneIcon/> Loading source data
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    :
                        <DonutChart
                            chartData={props?.chartData ?? undefined}
                            total={reportingData[currentAccountID]?.deceased?.termDeaths?.reportedDeaths?.totalCount}
                            selectedGroup={selectedGroup?.name}
                            selectedTerm={displayTermTitle()}
                            session={session}
                            placeholder={individualData?.deathTrac?.length === 0}
                            promptMessage={`When we get your first ${accountData?.reportFrequency?.toLowerCase()} report, more statistical information will be available here`}
                        />
                    }
                </div>
            </div>
            <div className="right panel bR g">
                <div className="header bold cC">
                    <div className="g dG">
                        Quick Reports
                    </div>
                </div>
                <div className="quickReports g f fR cC s e dG">
                    <Link
                        to={"/reporting"}
                        className="option bold f cC g bR"
                        onClick={(e)=>{selectSystemReport(e, reportByRecordID(systemReportPointer["allIndividuals"]))}}
                    >
                        <div className="icon f cC">
                            <PeopleAltIcon/>
                        </div>
                        <div className="prompt f g dG e">
                            <div className="f cL">
                                {`Individuals on Record`}
                            </div>
                            <div className="f cR">
                                {`${accountSummaryData?.totalIndividuals?.toLocaleString() ?? 0}`}
                            </div>
                        </div>
                    </Link>
                    <Link
                        to={"/reporting"}
                        className="option bold f cC g bR"
                        onClick={(e)=>{selectSystemReport(e, reportByRecordID(systemReportPointer["allDeceased"]))}}
                    >
                        <div className="icon f cC">
                            <PeopleAltIcon/>
                        </div>
                        <div className="prompt f g dG e">
                            <div className="f cL">
                                {`Deceased on Record`}
                            </div>
                            <div className="f cR">
                                {`${reportingData[currentAccountID]?.deceased?.reportedDeaths?.totalCount?.toLocaleString() ?? 0}`}
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="header bold cC">
                    {`${accountData?.reportFrequency} Reports`} 
                </div>
                <div className="frequencyReportList body f oH b s b e">
                    <div className="content wrap f tO bR">
                        <div className={`list f g fC tO dG${(!allReports || frequencyReportList?.length === 0)? " alt" : " dP"}`}>
                            {allReports && Object?.keys(allReports)?.length ?
                                frequencyReportList?.length === 0 ?
                                    <div className="f cC empty">
                                        <div className="g dG cC">
                                            {accountData?.reportFrequency === "Weekly" ? <DateRangeIcon/> : <TodayIcon/>}
                                            {`There are currently no ${accountData?.reportFrequency?.toLowerCase()} reports for your account.`}                                            
                                        </div>
                                    </div>
                                :
                                frequencyReportList
                            :
                                <div className="loading f cC g fC fR">
                                    <div className="f cC">
                                        <div className="g dG cC">
                                            <CircularProgress color="inherit"/>
                                            <div className="dG cC f">
                                                <TableRowsTwoToneIcon color="inherit"/>
                                                {`Loading ${accountData?.reportFrequency?.toLowerCase()} reports`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    )
};

export default Dashboard;