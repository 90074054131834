import React from 'react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Tooltip } from '@chakra-ui/react';

import '../CSS/Upload.css';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from '@mui/icons-material/Warning';

function Dropzone({onDrop, type}) {
  const [invalidFile, setInvalidFile] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(false);

  // Code to disable upload
  // let { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop: (type === 'sftp') 
  //     ? (acceptedFiles) => {
  //         const allowedTypes = type === 'sftp' 
  //           ? null  // null will be used to allow all file types
  //           : [
  //               "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
  //               "application/vnd.ms-excel",
  //               "text/csv"
  //             ];
  
  //         const updatedFiles = acceptedFiles.filter(file => 
  //           // If allowedTypes is null (sftp), accept all files, otherwise check file types
  //           allowedTypes === null || allowedTypes.includes(file.type)
  //         ).map(file => 
  //           Object.assign(file, {
  //             preview: URL.createObjectURL(file)
  //           })
  //         );
  
  //         if (updatedFiles.length > 0) {
  //           onDrop(updatedFiles);
  //           setInvalidFile(false);
  //         } else {
  //           // No valid files, set invalid flag
  //           setInvalidFile(true);
  //         }
  //       }
  //     : () => setUploadDisabled(true)  // Provide a valid function here
  // });
  
  
  let {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: 
        acceptedFiles => {
          const allowedTypes = type === 'sftp' 
            ? null  // null will be used to allow all file types
            : [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
                "application/vnd.ms-excel",
                'text/csv'
              ];
          
          const updatedFiles = acceptedFiles.filter(file => 
            // If allowedTypes is null (sftp), accept all files, otherwise check file types
            allowedTypes === null || allowedTypes.includes(file.type)
          ).map(file => 
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          );

          if (updatedFiles.length > 0) {
            onDrop(updatedFiles);
            setInvalidFile(false);
          } else {
            // No valid files, set invalid flag
            setInvalidFile(true);
          }
        }
      // accept: [
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //   "application/vnd.ms-excel",
      //   "text/csv"
      // ]
  });

  return (
    (type === 'sftp' ?
    <div className='f uploadZone oH' style={{ backgroundColor: isDragActive ? '#e0f2f1' : null, borderColor: isDragActive ? '#26a69a' : null, color: isDragActive ? '#26a69a' : null}}>
      <div className='cC f oA'{...getRootProps()}>
        <input {...getInputProps()} />
        {
          <div>
            <div className="f cC oA gR1 g">               
                  <div className='warningBox bR' style={{ backgroundColor: isDragActive ? '#e0f2f1' : null, borderColor: isDragActive ? '#26a69a' : null, color: isDragActive ? '#26a69a' : null}}>
                        <div className='cC'>
                          <WarningAmberIcon fontSize='large' sx={{ color:'gold'}}/>
                          Upload will use SFTP to transfer your file
                        </div>
                        <div style={{fontSize:'16px'}}>Please ensure that your file contains ALL of your individuals</div>
                  </div>
                {isDragActive ?
                    <p className='gR2 grayText' style={{fontSize:'20px'}}>Drop The Files Here ...</p> :
                    <p className='gR2 grayText' style={{fontSize:'20px'}}>Drag & Drop Files Here <br/> or Click to Select Files <br/> <FileUploadIcon/></p>
                } 
                {invalidFile && 
                 <div className='errorText'>Invalid File Type. Please use our File Template or upload a valid Excel file</div>    
                }
            </div>
          </div>
        }
      </div>
    </div>
    :
    // <Tooltip hasArrow label='.xlsx and .csv files are accepted' bg='#FEEEE' placement='bottom' arrowSize={15}>
    <div className='f uploadZone oH' style={{ backgroundColor: isDragActive ? '#e0f2f1' : null, borderColor: isDragActive ? '#26a69a' : null, color: isDragActive ? '#26a69a' : null}}>
      <div className='cC f oA'{...getRootProps()}>
        <input {...getInputProps()} />
        {
          <div>
            <div className="f cC oA gR1 g">
                  <div className='warningBox bR' style={{ backgroundColor: isDragActive ? '#e0f2f1' : null, borderColor: isDragActive ? '#26a69a' : null, color: isDragActive ? '#26a69a' : null}}>
                        <div className='cC'>
                          <WarningAmberIcon fontSize='large' sx={{ color:'gold'}}/>
                          Upload will replace all existing entries
                        </div>
                        <div style={{fontSize:'16px'}}>Please ensure that your file contains ALL of your individuals</div>
                  </div>       
                {isDragActive ?
                  
                    <p className='gR2 grayText' style={{fontSize:'20px'}}>Drop The Files Here ...</p> :
                    <>
                    <p className='gR2 grayText' style={{fontSize:'20px'}}>Drag & Drop Files Here <br/> or Click to Select Files <br/> <FileUploadIcon/></p>
                    <p className='gR3 grayText' style={{fontSize:'18px'}}>Accepted File Types: .xlsx and .csv </p>
                    {/* {!uploadDisabled && 
                      <div className='errorText'>As part of our ongoing efforts to enhance security, our <span className='bold'>Uploading feature</span> will be temporarily unavailable. We expect to be back up and running by Wednesday morning. If you have any questions, please reach out to us at help@abltech.com. Thank you for your patience and understanding.</div>
                    } */}
                    </>
                } 
                {invalidFile && 
                 <div className='errorText'>Invalid File Type. Please use our File Template or upload a valid Excel file</div>    
                }
                {/* {uploadDisabled && 
                 <div className='errorText'>As part of our ongoing efforts to enhance security, our <span className='bold'>Uploading feature</span> will be temporarily unavailable. We expect to be back up and running by Wednesday morning. If you have any questions, please reach out to us at help@abltech.com. Thank you for your patience and understanding.</div>    
                } */}
            </div>
          </div>
        }
      </div>
    </div>
    // </Tooltip>
    )

  );
}

export default Dropzone;
