import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import UploadIcon from '@mui/icons-material/Upload';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import RingVolumeTwoToneIcon from '@mui/icons-material/RingVolumeTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';

import DownloadResources from "./Download.js";

function ReportingVars(props){

  // Modified to set stem as individual even if summary report
  function selectIndividual(session, rowObj, currentStructure, importedList){
    if(currentStructure !== importedList){
      if(!session?.individual?.data[session?.handler?.data?.currentAccountID]?.selectedProfile?.individuals?.[rowObj?.record_id]){
        session?.individual?.setData(`${session?.handler?.data?.currentAccountID}.selectedProfile.individuals[${rowObj?.record_id}]`, {
            stem : 'individuals', recordID : rowObj?.record_id
        });
      }
      session?.individual?.setData(`${session?.handler?.data?.currentAccountID}.selectedProfile.active`, {
        stem : 'individuals', recordID : rowObj?.record_id, profile : rowObj
      });
      if(!session?.reporting?.data[session?.handler?.data?.currentAccountID]?.profileOpen){
        session?.env?.setOverlay("individualProfile");
      }
    }
  }
  const reportDataHandler = {
    deceased: {
      icon: <PersonSearchIcon />,
      branchType: "deathTrac",
      prompt: "Deceased",
      stem: "deceased",
      type: "inherited",
      onClick : (session, rowObj, currentStructure, importedList) => {
        selectIndividual(session, rowObj, currentStructure, importedList);
      }
    },
    individuals: {
      icon: <GroupsIcon />,
      branchType: "deathTrac",
      prompt: "Individuals",
      stem: "individuals",
      type: "generated",
      onClick : (session, rowObj, currentStructure, importedList) => {
        selectIndividual(session, rowObj, currentStructure, importedList);
      }
    },
    summary: {
      icon: <AssessmentTwoToneIcon />,
      branchType: "deathTrac",
      prompt: "Summary",
      stem: "summary",
      type: "dynamic",
      hasPath : false,
      onClick : (session, rowObj, currentStructure, importedList) => {
        selectIndividual(session, rowObj, currentStructure, importedList);
      }
    },
    upload: {
      icon: <UploadIcon />,
      branchType: "policies",
      prompt: "Serviced Policies",
      stem: "servicing",
      type: "inherited",
    },
    scheduled: {
      icon: <EventRepeatIcon />,
      branchType: "deathTrac",
      prompt: "Scheduled",
      stem: "scheduled",
      type: "dynamic",
    },
  };

  const reportSchema = {
    branch : undefined,
    columns : undefined,
    stem : undefined,
    columns : [],
    name : undefined,
    ID : undefined,
    criteria : {
      current : {
        attr : undefined
      },
      existing : [],
      groupBy : [],
    },
    details : {
      name : undefined,
      description : undefined,
      displayType: "dynamic",
      shareType : undefined,
      shareList : undefined, 
      reoccurType  : false,
      scrollType : "pagination",
      editAccessList : ["owner"],
      viewAccessList : ["owner"],
      startDate : undefined,
      endDate : undefined,
      system : false
    },
    query : "",
    showAll : false,
    reportType : undefined,
    lastPageIndex : 1,
    lastRecordID : undefined,
    generated : undefined,
    generationTime : undefined,
    group : undefined,
    editable : undefined,
    referenceBranch : undefined,
    referenceStem : undefined,
    referenceRecordID : undefined,
    groupColumns : undefined,
    subReport: {
      list: undefined,
      query: "(recordID not_blank 'true')",
      name : undefined,
      columns : undefined,
    },
    sortedListResults : undefined,
  };

  const defaultColumns = {
    deceased : {
      plainArray : [
        "ssn",
        "last_name",
        "first_name",
        "dob",
        "city",
        "state",
        "internal_group",
        "internal_group_2",
        "internal_group_3",
        "internal_id",
        "abl_dod",
        "abl_ssn",
        "abl_last_name",
        "abl_first_name",
        "abl_dob",
        "abl_city",
        "abl_state",
        "abl_source",
        "death_confirmation_date",
        "abl_url"
      ],
      detailedArray : [
        {
          id: "0",
          columnName: "ssn",
          friendlyTerm: "SSN",
          frozen: false,
        },
        {
          id: "1",
          columnName: "last_name",
          friendlyTerm: "Last",
          frozen: false,
        },
        {
          id: "2",
          columnName: "first_name",
          friendlyTerm: "First",
          frozen: false,
        },
        {
          id: "3",
          columnName: "dob",
          friendlyTerm: "DOB",
          frozen: false,
          editable : true,
        },
        {
          id: "4",
          columnName: "city",
          friendlyTerm: "City",
          frozen: false,
        },
        {
          id: "5",
          columnName: "state",
          friendlyTerm: "State",
          frozen: false,
          editable : true,
        },
        {
          id: "6",
          columnName: "internal_group",
          friendlyTerm: "Client Group",
          frozen: false,
          editable : true,
        },
        {
          id: "7",
          columnName: "internal_group_2",
          friendlyTerm: "Client Group 2",
          frozen: false,
          editable : true,
        },
        {
          id: "8",
          columnName: "internal_group_3",
          friendlyTerm: "Client Group 3",
          frozen: false,
          editable : true,
        },
        {
          id: "9",
          columnName: "individual_id",
          friendlyTerm: "Client ID",
          frozen: false,
        },
        {
          id: "10",
          columnName: "abl_dod",
          friendlyTerm: "ABL Date of Death",
          mobileFriendlyTerm: "ABL DOD",
          frozen: false,
          altColor : true,
        },
        {
          id: "11",
          columnName: "abl_ssn",
          friendlyTerm: "ABL Social Security Number",
          mobileFriendlyTerm: "ABL SSN",
          frozen: false,
          altColor : true,
        },
        {
          id: "12",
          columnName: "abl_last_name",
          friendlyTerm: "ABL Last",
          frozen: false,
          altColor : true,
        },
        {
          id: "13",
          columnName: "abl_first_name",
          friendlyTerm: "ABL First",
          frozen: false,
          altColor : true,
        },
        {
          id: "14",
          columnName: "abl_dob",
          friendlyTerm: "ABL DOB",
          frozen: false,
          altColor : true,
        },
        {
          id: "15",
          columnName: "abl_city",
          friendlyTerm: "ABL City",
          frozen: false,
          altColor : true,
        },
        {
          id: "16",
          columnName: "abl_state",
          friendlyTerm: "ABL State",
          frozen: false,
          altColor : true,
        },
        {
          id: "17",
          columnName: "abl_source",
          friendlyTerm: "ABL Source",
          frozen: false,
          altColor : true,
        },
        {
          id: "18",
          columnName: "death_confirmation_date",
          friendlyTerm: "Date of Confirmation",
          frozen: false,
          altColor : true,
        },
        {
          id: "19",
          columnName: "abl_url",
          friendlyTerm: "ABL URL",
          frozen: false,
          altColor : true,
        },
      ],
    },
    individuals : {
      plainArray : [
        "ssn",
        "last_name",
        "first_name",
        "dob",
        "city",
        "state",
        "internal_group",
        "internal_group_2",
        "internal_group_3",
        "internal_id",
      ],
      detailed : [
        {
          id: "0",
          columnName: "ssn",
          friendlyTerm: "SSN",
          frozen: false,
        },
        {
          id: "1",
          columnName: "last_name",
          friendlyTerm: "Last",
          frozen: false,
        },
        {
          id: "2",
          columnName: "first_name",
          friendlyTerm: "First",
          frozen: false,
        },
        {
          id: "3",
          columnName: "dob",
          friendlyTerm: "DOB",
          frozen: false,
          editable : true,
        },
        {
          id: "4",
          columnName: "city",
          friendlyTerm: "City",
          frozen: false,
        },
        {
          id: "5",
          columnName: "state",
          friendlyTerm: "State",
          frozen: false,
          editable : true,
        },
        {
          id: "6",
          columnName: "internal_group",
          friendlyTerm: "Client Group",
          frozen: false,
          editable : true,
        },
        {
          id: "7",
          columnName: "internal_group_2",
          friendlyTerm: "Client Group",
          frozen: false,
          editable : true,
        },
        {
          id: "8",
          columnName: "internal_group_3",
          friendlyTerm: "Client Group",
          frozen: false,
          editable : true,
        },
        {
          id: "9",
          columnName: "individual_id",
          friendlyTerm: "Client ID",
          frozen: false,
        },
      ]
    }
  }

  const groupBySummaries = {
    string : ["Equal Value", "First Letter", "First Word"],
    date : ["Equal Value", "Day", "Month", "Year", "Quarter"], 
    int : ["Equal Value", "1", "10", "100", "1,000", "10,000", "100,000", "1,000,000"],
    float: ["Equal Value","0.1", "1", "10", "100", "1,000", "10,000", "100,000", "1,000,000"],
    generatedList: ["Equal Value", "First Letter", "First Word"],
    dropdown : ["Equal Value", "First Letter", "First Word"]
  };

  const combineBySummaries = {
    string : ["Count"],
    date : ["Min", "Max", "Count"], 
    int : ["Min", "Max", "Count", "Average", "Sum"],
    float : ["Min", "Max", "Count", "Average", "Sum"],
    generatedList: ["Count"],
    dropdown : ["Count"]
  };

  const [data, setData] = useState({
    initialized: false,
    selectedReport : reportSchema,
    allReports : undefined,
    reset : reportSchema,
    sorting : [],
    searchValue : undefined,
    defaultColumns,
    reportGroups : {},
    reportDataHandler : reportDataHandler,
    dataPointer : "individual",
    selectAllVar : "record_id",
    appSourceType : "Case",
    specialColumns : {
      rowIndex : false,
      select : false,
      view : false,
    },
    selectionState : {
      selectedRows: [],
      lastClickedRowIndex: null
    },
    groupBySummaries,
    combineBySummaries,
    summaryCells: undefined,
    downloadFileType : localStorage.getItem('downloadFileType') ?? "XLSX",
    downloadDateType : localStorage.getItem('downloadDateType') ?? "MM/DD/YYYY",
    reportListOpen: true,
    profileOpen: false,
    selectedRowIndex: '',
    pagination: {
      showMenu : false,
      rowMax : 100,
      index : 1,
    }
  });

  // const [pagination, setPagination] = useState({
  //         showMenu : false,
  //         rowMax : rowMax?.reporting?.rowMax ?? 100,
  //         index : selectedReport?.lastPageIndex ?? 1,
  //     });

  const initializeData = (accountNumbers) => {
    const initialData = {};
    accountNumbers.forEach((accountNumber) => {
      initialData[accountNumber] = {
        initialized: true,
        selectedReport : reportSchema,
        allReports : undefined,
        reset : reportSchema,
        sorting : [],
        searchValue : undefined,
        defaultColumns,
        reportGroups : {},
        reportDataHandler: reportDataHandler,
        dataPointer : "individual",
        selectAllVar : "record_id",
        appSourceType : "Case",
        specialColumns : {
          rowIndex : false,
          select : false,
          view : false,
        },
        selectionState : {
          selectedRows: [],
          lastClickedRowIndex: null
        },
        groupBySummaries,
        combineBySummaries,
        summaryCells: undefined,
        downloadFileType : localStorage.getItem('downloadFileType') ?? "XLSX",
        downloadDateType : localStorage.getItem('downloadDateType') ?? "MM/DD/YYYY",
        reportListOpen: true,
        profileOpen: false,
        selectedRowIndex: '',
        pagination: {
          showMenu : false,
          rowMax : 100,
          index : 1,
        }
      };
    });
    setData(initialData);
  };

  
  const ops = {
    "match": (a, b) => a && b && String(a).toLowerCase() === String(b).toLowerCase(),
    "not_match": (a, b) => a && b && String(a).toLowerCase() !== String(b).toLowerCase(),
    "contain": (a, b) => a && b && String(a).toLowerCase().includes(String(b).toLowerCase()),
    "not_contain": (a, b) => a && b && !String(a).toLowerCase().includes(String(b).toLowerCase()),
    "blank": (a, b) => (b === 'true' ? !a : !!a),
    "not_blank": (a, b) => (b === 'true' ? !!a : !a),
    "greater_than": (a, b) => Number(a) > Number(b),
    "less_than": (a, b) => Number(a) < Number(b),
    "before": (a, b) => new Date(a) < new Date(b),
    "after": (a, b) => new Date(a) > new Date(b),
    "in_between": (a, b) => {
      const [start, end] = b.split(" to ");
      const dateA = new Date(a);
      const startDate = new Date(start);
      startDate.setHours(0, 0, 0, 0); // Set to start of the day
      const endDate = new Date(end);
      endDate.setHours(23, 59, 59, 999); // Set to end of the day
  
      return dateA >= startDate && dateA <= endDate;
    },
    "on_or_before": (a, b) => new Date(a) <= new Date(b),
    "on_or_after": (a, b) => new Date(a) >= new Date(b),
  };

  function formulatePath(rowObj, selectedReport){
    const pathData = {
        branch : selectedReport?.referenceBranch ?? selectedReport?.branch,
        stem : selectedReport?.referenceStem ?? selectedReport?.stem,
        pointer : undefined
    }

    if(pathData?.stem === "origination"){
        pathData.pointer = rowObj?.relatedPolicyID;
    }else if(pathData?.stem === "servicing"){
        pathData.branch += "/servicing";
        pathData.pointer = rowObj?.recordID;
    }else if(pathData?.stem === "bids"){
        pathData.pointer = rowObj?.recordID;
    }

    return (`/${pathData?.branch}/${pathData?.pointer}`)
  }

  function deepCopy(obj, referenceMap = new WeakMap()) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (referenceMap.has(obj)) {
        return referenceMap.get(obj);
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    if (obj instanceof RegExp) {
        return new RegExp(obj.source, obj.flags);
    }

    const copy = Array.isArray(obj) ? [] : Object.create(Object.getPrototypeOf(obj));
    referenceMap.set(obj, copy);

    for (const [key, value] of Object.entries(obj)) {
        copy[key] = deepCopy(value, referenceMap);
    }

    return copy;
  }

  const updateReport = (path, attr, value) => {
    setData((prevState) => {
      const newState = {...prevState}; // Deep copy using the custom deepCopy function
      const pathSegments = path?.split('.');
      let current = newState;

      for (const segment of pathSegments) {

        if (segment.includes('[')) {
          // Handle array access within the path
          const [key, indexStr] = segment.split('[');
          const index = parseInt(indexStr.replace(']', ''), 10);

          if (!current[key]) {
            // Initialize an array if it doesn't exist
            current[key] = [];
          }

          if (!current[key][index]) {
            // Initialize an object within the array if it doesn't exist
            current[key][index] = {};
          }

          current = current[key][index];
        } else {
          // Handle regular object properties within the path
          if (!current[segment]) {
            // Initialize an object if it doesn't exist
            current[segment] = {};
          }

          current = current[segment];
        }
      }

      if (attr === null) {
        // marketValue, assignedUsersList, issueDate, accountManager, caseProcessor
        // Update the entire path's value to the new value
        newState[pathSegments[0]] = value;
      } else if (typeof attr === 'object' && !Array.isArray(attr)) {
        Object.assign(current, attr);
      } else {
        if(Array.isArray(attr)){
          newState[pathSegments[0]] = attr;
        }else{
          current[attr] = value;
        }
        // Update only the specified attribute
      }

      return newState;
    });
  };

  const updateSelectedReport = (attr, value, accID) => {
    setData((prevState) => ({
      ...prevState,
      [accID]:{
        ...prevState[accID],
        selectedReport: {
          ...prevState[accID]?.selectedReport,
          [attr]: value,
        },
      }
    }));
  }

  function updateRows(session, rows) {
    // Add 'taskID' to each row, equivalent to its 'recordID'
    const updatedRows = rows.map(row => ({
      ...row,  // Spread the original row object
      taskID: row?.recordID  // Add or overwrite 'taskID' with the value of 'recordID'
    }));
  
    const paramVals = {
      tasks: updatedRows,  // Use the updated rows with 'taskID'
    };
  
  
    session?.env?.functions?.buildFetchRequest("marketplace/updateBidStatuses", paramVals)
      .then(response => response.json())
      .then(resData => {
        if (resData.status === 200) {
          session?.case?.functions?.updateCases(resData?.results, "bids", "bids");
        }
      });
  }

  function downloadSelectedFiles(rows, session) {
    // Add 'taskID' to each row, equivalent to its 'recordID'
    const updatedRows = rows.map(row => ({
      ...row,  // Spread the original row object
      taskID: row?.recordID  // Add or overwrite 'taskID' with the value of 'recordID'
    }));
  
    const paramVals = {
      tasks: updatedRows,  // Use the updated rows with 'taskID'
    };
  
    const headers = {
      Accept: "application/zip", // Ensure the server knows we expect a ZIP file response
    };

    session?.env?.functions?.buildFetchRequest("marketplace/downloadDriveFolders", paramVals, headers)
    .then(blob => {
      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(blob);
  
      // Create an anchor element and set its attributes for download
      const a = document.createElement('a');
      a.href = url;
      a.download = "downloadedFiles.zip";  // Set the default filename for the download
  
      // Append the anchor to the body, click it to trigger the download, and then remove it
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      // Clean up by revoking the Blob URL
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });

  }
  
  const selectRowHandler = (clickedRowIndex, clickedRecordId, rowsList, stem, currentAccountID) => (event) => {
    event.preventDefault();
    let newSelectedRows = [...data[currentAccountID]?.selectionState?.selectedRows];
    const lastClicked = data[currentAccountID]?.selectionState.lastClicked || {};
    const selectAllVar = data[currentAccountID]?.selectAllVar;
  
    const updateSelection = (rowObj, shouldSelect) => {
      const rowSelection = {
        recordID: rowObj[selectAllVar],
        folder_name: typeof data[currentAccountID]?.reportDataHandler?.[stem]?.folderName === 'function' ? data[currentAccountID].reportDataHandler[stem].folderName(rowObj) : undefined,
        drive_name: rowObj?.[data[currentAccountID]?.reportDataHandler?.[stem]?.driveName]
      };
      if (shouldSelect && !newSelectedRows.some(row => row.recordID === rowObj[selectAllVar])) {
        newSelectedRows = [...newSelectedRows, rowSelection];
      } else if (!shouldSelect) {
        newSelectedRows = newSelectedRows.filter(row => row.recordID !== rowObj[selectAllVar]);
      }
    };
  
    if (event.shiftKey && lastClicked.rowIndex !== undefined) {
      const rangeStart = Math.min(clickedRowIndex, lastClicked.rowIndex);
      const rangeEnd = Math.max(clickedRowIndex, lastClicked.rowIndex);
      const shouldSelect = !newSelectedRows.some(row => row.recordID === clickedRecordId);
      rowsList.slice(rangeStart, rangeEnd + 1).forEach(rowObj => {
        if (rowObj) updateSelection(rowObj, shouldSelect);
      });
    } else {
      const rowObj = rowsList.find(row => row[selectAllVar] === clickedRecordId);
      if (rowObj) updateSelection(rowObj, !newSelectedRows.some(row => row.recordID === clickedRecordId));
    }
  
    // Update state once after all selections are processed
    setData(prev => ({
      ...prev,
      selectionState: {
        selectedRows: newSelectedRows,
        lastClicked: { rowIndex: clickedRowIndex, recordID: clickedRecordId }
      }
    }));
  };

  const toggleAllSelectRows = (rowsList, stem, direct, session) => {
    const allRowIds = rowsList?.map(row => ({
      recordID: row?.[data[session?.handler?.data?.currentAccountID]?.selectAllVar],
      folder_name: typeof data[session?.handler?.data?.currentAccountID]?.reportDataHandler?.[stem]?.folderName === 'function' ? data[session?.handler?.data?.currentAccountID]?.reportDataHandler[stem].folderName(row) : undefined,
      drive_name: row?.[data[session?.handler?.data?.currentAccountID]?.reportDataHandler?.[stem]?.driveName]
    }));
  
    const newSelection = direct !== undefined ? 
      (direct ? allRowIds : []) :
      rowsList.every(row => data[session?.handler?.data?.currentAccountID]?.selectionState?.selectedRows?.some(selected => selected?.recordID === row?.[data[session?.handler?.data?.currentAccountID]?.selectAllVar])) ? 
      [] : allRowIds;
  
    setData(prev => ({
      ...prev,
      selectionState: {
        ...prev.selectionState,
        selectedRows: newSelection,
        lastClicked: null
      }
    }));
  };
  
  
  useEffect(() => {
    const handleKeyUp = (event) => {
        if (event.key === 'Shift') {
            // Reset the anchor when the Shift key is released
            setData(prev => ({
                ...prev,
                selectionState: {
                    ...prev.selectionState,
                    lastClicked: null
                }
            }));
        }
    };

    window.addEventListener('keyup', handleKeyUp);

    return () => {
        window.removeEventListener('keyup', handleKeyUp);
    };
  }, [setData]); // Include setData if it's a prop or context to ensure it's the latest function


  function evaluateQuery(query, item) {
    // Handle logical OR operator
    if (query.OR) {
        return query.OR.some(subQuery => evaluateQuery(subQuery, item));
    }
    
    // Handle logical AND operator
    if (query.AND) {
        return query.AND.every(subQuery => evaluateQuery(subQuery, item));
    }

    // Handle comparison operators
    for (let operator in query) {
        if (ops[operator]) {
            for (let field in query[operator]) {
                const queryValue = query[operator][field];
                const itemValue = item[field];

                // Handle the case where the query value is an empty string
                if (queryValue === '') {
                    if (operator === 'match' && itemValue !== '') {
                        return false; // If 'match' and not empty, return false.
                    }
                    if (operator === 'not_match' && itemValue === '') {
                        return false; // If 'not_match' and empty, return false.
                    }
                } else {
                    // Handle other comparison operators
                    if (!ops[operator](itemValue, queryValue)) {
                        return false; // If one comparison fails, return false for the current operator.
                    }
                }
            }
        }
    }

    // If we reach this point, then all checks passed for the current query/operator.
    return true;
  }

  function convertDateFormat(dateStr) {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + 1);  

    const month = String(date.getMonth() + 1).padStart(2, '0');  
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    
    // Format the date as MM/DD/YYYY
    return `${month}/${day}/${year}`;
}

function deepCopy(obj) {
  if (obj === null || typeof obj !== 'object') {
      return obj;
  }
  if (Array.isArray(obj)) {
      return obj.map(deepCopy);
  }
  const result = {};
  for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
          result[key] = deepCopy(obj[key]);
      }
  }
  return result;
}

  function updateSelectedReportDates(session, selectedReport){
    const updatedDatesReport = deepCopy(selectedReport)
    updatedDatesReport?.list?.forEach(record => {
      for (const col in record) {
          if (session?.individual?.data[session?.handler?.data?.currentAccountID].individualAttributesData?.[col]?.formType == 'date') {
              record[col] = convertDateFormat(record[col]);
          }
      }
  });
    return updatedDatesReport
  }

  function downloadReport(session, formattedDates){
    const downloadResources = DownloadResources({session});
    if(formattedDates){
      const updatedDatesReport = updateSelectedReportDates(session, session?.reporting?.data[session?.handler?.data?.currentAccountID]?.selectedReport);
      downloadResources.functions.downloadReport(updatedDatesReport);
    }else{
      downloadResources.functions.downloadReport(session?.reporting?.data[session?.handler?.data?.currentAccountID]?.selectedReport);
    }
    
  }

  function reportType(stem){
    return reportDataHandler?.[stem]?.type;
  }

  function sessionReportBranches(opportunityTypes){
    const reportablePages = [
      "individuals",
      "deceased",
    ];

    return(opportunityTypes || []).filter(type => reportablePages.includes(type));
  }

  function filterItemsWithQuery(query, items) {
    if(!query){
      return;
    }
    query = parse(query);
    return items?.filter(item => evaluateQuery(query, item));
  }

  function parse(queryStr) {

    let idx = 0;
    const tokens = [];

    function getNextToken(queryStr) {
      const match = /^(\s*(\()|(\))|(AND)|(OR)|(\w+ (match|not_match|contain|not_contain|blank|not_blank|greater_than|less_than|before|after|in_between|on_or_before|on_or_after) '.*?'( to '.*?')?)\s*)/.exec(queryStr);
      if (match) {
        return match[0].trim();
      } else {
        return null;
      }
    }

    function tokenize(queryStr) {
        while (queryStr?.length > 0) {
            const token = getNextToken(queryStr);
            if (token) {
                tokens.push(token);
                queryStr = queryStr?.substring(token?.length)?.trim();
            } else {
                throw new Error("Invalid token encountered");
            }
        }
    }
    
    function parseExpression() {
        const termResult = parseTerm();
        if (tokens[idx] === "OR") {
            idx++;
            return { "OR": [termResult, parseExpression()] };
        }
        return termResult;
    }
    
    function parseTerm() {
        const factorResult = parseFactor();
        if (tokens[idx] === "AND") {
            idx++;
            return { "AND": [factorResult, parseTerm()] };
        }
        return factorResult;
    }
    
    function parseFactor() {
        if (tokens[idx] === "(") {
            idx++;
            const expr = parseExpression();
            if (tokens[idx] !== ")") {
                throw new Error("Expected closing parenthesis");
            }
            idx++;
            return expr;
        }
        return parseCondition();
    }
  
    function parseCondition(){
      const [field, operator, ...valueParts] = tokens?.[idx]?.split(" ");
      idx++;
      const value = valueParts.join(" ").replace(/'/g, "");
      return { [operator]: { [field]: value } };
    }

    tokenize(queryStr);
    const result = parseExpression();
    if (idx !== tokens.length) {
        throw new Error("Unexpected tokens at the end");
    }
    return result;
  }

  const isEqualWithoutAttributes = (objA, objB, pathsToIgnore = []) => {
    const removeObjectPropertiesByPath = (obj, paths) => {
      if (!paths.length || !obj) return obj;
      let newObj = JSON.parse(JSON.stringify(obj)); // Deep copy
  
      paths.forEach(path => {
        const parts = path.split('.');
        const removeProperty = (current, parts) => {
          if (!current || parts.length === 0) return;
          const part = parts[0];
          if (part === '*' && Array.isArray(current)) {
            current.forEach(item => removeProperty(item, parts.slice(1)));
          } else if (parts.length > 1) {
            removeProperty(current[part], parts.slice(1));
          } else {
            // console.log(`Removing property: ${part}`);
            delete current[part];
          }
        };
  
        removeProperty(newObj, parts);
      });
  
      return newObj;
    };
  
    const cleanObjA = removeObjectPropertiesByPath(objA, pathsToIgnore);
    const cleanObjB = removeObjectPropertiesByPath(objB, pathsToIgnore);
  
    const deepEqual = (a, b) => {
      if (a === b) return true;
      if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) return false;
  
      const keysA = Object.keys(a);
      const keysB = Object.keys(b);
  
      if (keysA.length !== keysB.length) {
        // console.log('Different number of keys');
        return false;
      }
  
      for (const key of keysA) {
        if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
          // console.log(`Difference found in key: ${key}, a[key]=${JSON.stringify(a[key])}, b[key]=${JSON.stringify(b[key])}`);
          return false;
        }
      }
  
      return true;
    };
  
    return deepEqual(cleanObjA, cleanObjB);
  };

  function buildQuery(criteria) {
    let query = '';
    let openBracket = false;

    if (!criteria || !criteria?.length) {
        return;
    }

    const criteriaWithOriginalKeys = criteria?.map((attribute, index) => ({
        originalIndex: index, // Add the original index to each element
        ...attribute, // Copy the rest of the attributes
    }));

    criteriaWithOriginalKeys.sort((a, b) => a.groupID - b.groupID);
    criteriaWithOriginalKeys.map((attribute) => {
        const parentKeys = criteriaWithOriginalKeys.filter((attr) => {
            const currAttr = attr;
            const currAttrCriterion = Object?.keys(currAttr?.criterion)?.[0];
            const currAttrCriterionValue = currAttr?.criterion[currAttrCriterion];
            const isAttrInBetween = currAttrCriterion === "in_between";
            const inBetweenCatch = isAttrInBetween && (currAttrCriterionValue?.startDate && currAttrCriterionValue?.endDate);

            return currAttr?.relativeKey === undefined &&
                !currAttr?.inactive &&
                (currAttr?.formType === "generatedList" ?
                    currAttrCriterionValue !== undefined
                    :
                    (currAttrCriterionValue !== undefined && currAttrCriterionValue !== '') && (isAttrInBetween ? inBetweenCatch : true));
        });

        const firstParentKey = attribute === parentKeys?.[0];
        const index = attribute.originalIndex;
        const currentIndex = parentKeys.findIndex(item => item.originalIndex === index);
        const nextGroup = parentKeys[currentIndex + 1];
        const baseCriteria = criteria?.[index];
        const criterion = Object.keys(baseCriteria.criterion)[0];
        const criterionValue = baseCriteria?.criterion?.[criterion];

        if (baseCriteria?.relativeKey !== undefined || !baseCriteria?.attr ||
            baseCriteria?.inactive === true || !criterion || (baseCriteria?.formType === "generatedList" && criterionValue === undefined) ||
            (baseCriteria?.formType !== "generatedList" && (criterionValue === undefined || criterionValue === '')) ||
            (criterion === "in_between" && (!criterionValue?.startDate || !criterionValue?.endDate))) {
            return null;
        } else {
            if (nextGroup?.groupOperator === "AND" && !openBracket && currentIndex === 0) {
                query += "(";
                openBracket = true;
            }

            query += firstParentKey ? "(" : ` ${baseCriteria?.groupOperator} (`;
            if (nextGroup?.groupOperator === "AND" && !openBracket && currentIndex > 0) {
                query += "(";
                openBracket = true;
            }

            if (criterion === "in_between") {
                query += `${baseCriteria?.attr} ${criterion} \`${criterionValue?.startDate}\` to \`${criterionValue?.endDate}\``;
            } else {
                query += `${baseCriteria?.attr} ${criterion} \`${criterionValue}\``;
            }
        }

        const childAttributes = criteria.filter(
            (childAttribute) => childAttribute.relativeKey === index
        );

        if (childAttributes.length > 0) {
            criteria.map((childAttribute, childIndex) => {
                const siblingCriteria = criteria?.[childIndex];
                const siblingCriterion = Object.keys(siblingCriteria?.criterion)[0];
                const siblingCriterionValue = siblingCriteria?.criterion?.[siblingCriterion];

                if (!siblingCriteria || !siblingCriteria?.attr || siblingCriteria?.inactive || (siblingCriteria?.formType === "generatedList" && siblingCriterionValue === undefined) ||
                    (siblingCriteria?.formType !== "generatedList" && (siblingCriterionValue === undefined || siblingCriterionValue === '')) ||
                    siblingCriterion === "in_between" && (!siblingCriterionValue?.startDate || !siblingCriterionValue?.endDate)) {
                    return null;
                }

                if (childAttribute?.relativeKey === index) {
                    query += ` ${baseCriteria?.inlineOperator} `;
                    if (siblingCriterion === "in_between") {
                        query += `${siblingCriteria?.attr} ${siblingCriterion} \`${siblingCriterionValue.startDate}\` to \`${siblingCriterionValue.endDate}\``;
                    } else {
                        query += `${siblingCriteria?.attr} ${siblingCriterion} \`${siblingCriterionValue}\``;
                    }
                }
            });
        }

        query += ")";
        if (nextGroup?.groupOperator !== "AND" && openBracket || !nextGroup?.groupOperator && openBracket) {
            query += ")";
            openBracket = false;
        }
    });

    if (openBracket) {
        query += ")";
        openBracket = false;
    }

    return query;
  }
  
  // useEffect(() => {
  //   console.log(data[session?.handler?.data?.currentAccountID]?.selectedReport);
  // }, [data[session?.handler?.data?.currentAccountID]?.selectedReport]);

  useEffect(() => {
    const handleKeyUp = (event) => {
        if (event.key === 'Shift') {
            // Reset the anchor when the Shift key is released
            setData(prev => ({
                ...prev,
                selectionState: {
                    ...prev.selectionState,
                    lastClicked: null
                }
            }));
        }
    };

    window.addEventListener('keyup', handleKeyUp);

    return () => {
        window.removeEventListener('keyup', handleKeyUp);
    };
  }, [setData]); // Include setData if it's a prop or context to ensure it's the latest function

  function requestResultBlock(request, requestObject, accID, test){
    if (requestObject?.requestingBatch) {
      return;
    }
    const rowMaxString = localStorage.getItem('storage');
    const rowMax = JSON.parse(rowMaxString);

    const paramVals = {
        queryArray : parse(request?.newQuery ?? data[accID]?.selectedReport?.query) ?? undefined,
        offset : request?.newOffset ?? requestObject?.pagination?.index,
        limit : rowMax?.reporting?.rowMax ?? request?.newLimit ?? requestObject?.pagination?.rowMax,
        columns : request?.newColumns ?? data[accID]?.selectedReport?.columns,
        sorting : request?.newSorting ?? data[accID]?.sorting,
        accountID: accID
    };

    if (typeof requestObject?.setRequestingBatch === 'function') {
      requestObject.setRequestingBatch(true);
    }

    requestObject?.session?.env?.functions?.buildFetchRequest("individual/getMany", paramVals)
    .then(response => response.json())
    .then(resData => {
        if(resData.status === 200){
          if (typeof requestObject?.setExistingColumnsCopy === 'function') {
            // requestObject?.setExistingColumnsCopy(request?.newColumns ?? data[accID]?.selectedReport?.columns);
          }

          if (typeof requestObject?.setRequestingBatch === 'function') {
            requestObject?.setRequestingBatch(false);
          }
          updateSelectedReport("list", resData?.individuals, accID);

          updateSelectedReport("totalFound", resData?.totalFound, accID);
          // requestObject?.setExistingColumnsCopy(request?.newColumns ?? data[accID]?.selectedReport?.columns);
          updateSelectedReport("lastPageIndex", requestObject?.pagination?.index, accID);
        }
        // requestObject?.setRequestingBatch(false);
    });
  }

  function downloadDashReport(selectedReport, sortedListResults, formattedDate) {
    const columnConfig = selectedReport?.columns;
    const visibleColumns = columnConfig?.map(column => column.columnName);
  
    // Sanitize the sheet name
    let sheetName = selectedReport?.details?.name || 'Sheet1';
    sheetName = sheetName.replace(/[:\\/?*\[\]]/g, '_');
  
    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const wsData = [];
  
    // Add header row
    const headerRow = visibleColumns?.map(columnName => {
      const columnConfigItem = columnConfig?.find(config => config.columnName === columnName);
      return columnConfigItem ? columnConfigItem?.friendlyTerm : columnName;
    });
    wsData.push(headerRow);
  
    // Add data rows
    sortedListResults?.forEach(row => {
      const values = visibleColumns?.map(columnName => {

        let value = ''
        if((columnName == "dob" || columnName == "abl_dod" ||columnName == "abl_dob" || columnName == "death_confirmation_date") && formattedDate){
          value = convertDateFormat(row[columnName]) || '';
        }else{
          value = row[columnName] || ''; // Ensure a default string if the value is falsy
        }
      
        // Convert the value to a string if it's not already one
        if (typeof value !== 'string') {
          value = String(value);
        }
      
        return value;
      });
      wsData.push(values);
    });
  
    // Create the worksheet from the data
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
  
    // Create binary Excel file
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  
    // Convert the binary string to a Blob and create a download link
    const s2ab = s => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    };
  
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", sheetName + ".xlsx"); // Ensure the file has a .xlsx extension
    
    document.body.appendChild(link);
    link.click();
    
    document.body.removeChild(link);
  }

  function disableIndividual(session, accID, recordID){
    const paramVals = {
        recordID: recordID,
        accountID: accID,
    };

    session?.env?.functions?.buildFetchRequest("individual/remove", paramVals)
        .then(response => response.json())
        .then(resData => {    
            //setLoadingStatus(false);
            if(resData.status === 200){
                //console.log("success");
            }else{
                //console.log("Error");
            }
    });
  }


  // function downloadReport(selectedReport, sortedListResults) {
  //   const columnConfig = selectedReport?.columns;
  //   const visibleColumns = columnConfig?.map(column => column.columnName);
  
  //   let csvContent = "data:text/csv;charset=utf-8,";
  
  //   // Encapsulate header titles in double quotes
  //   csvContent += visibleColumns?.map(columnName => {
  //     const columnConfigItem = columnConfig?.find(config => config.columnName === columnName);
  //     return `"${columnConfigItem ? columnConfigItem?.friendlyTerm.replace(/"/g, '""') : columnName}"`; // Replace internal quotes with double quotes for proper escaping
  //   }).join(",") + "\n";
  
  //   sortedListResults?.forEach(row => {
  //     const values = visibleColumns?.map(columnName => {
  //       let value = row[columnName] || ''; // Ensure a default string if the value is falsy
    
  //       // Convert the value to a string if it's not already one
  //       if (typeof value !== 'string') {
  //         value = String(value);
  //       }
    
  //       // Encapsulate each value in double quotes and escape existing quotes
  //       return `"${value.replace(/"/g, '""')}"`;
  //     });
  //     csvContent += values.join(",") + "\n";
  //   });
  
  //   const encodedUri = encodeURI(csvContent);
  
  //   const link = document.createElement("a");
  //   link.setAttribute("href", encodedUri);
  //   link.setAttribute("download", selectedReport?.details?.name + ".csv"); // Ensure the file has a .csv extension
  
  //   document.body.appendChild(link);
  //   link.click();
  
  //   document.body.removeChild(link);
  // }

  const functions = {
    filterItemsWithQuery,
    parse,
    updateSelectedReport,
    reportType,
    isEqualWithoutAttributes,
    requestResultBlock,
    sessionReportBranches,
    formulatePath,
    updateRows,
    downloadSelectedFiles,
    selectRowHandler,
    downloadReport,
    downloadDashReport,
    toggleAllSelectRows,
    initializeData,
    buildQuery,
    disableIndividual,
  }


  const reportingVars = {
    data,
    setData : updateReport,
    functions,
  }

  return reportingVars;
};

export default ReportingVars;