import React, { useEffect, useState } from 'react';

function ResearchVars(props){


    const setInputData = (updatedFields) => {
        setData((prevState) => ({
            ...prevState,
            inputData: {
                ...prevState.inputData,
                ...updatedFields, 
            },
        }));
    };
    
    const [data, setData] = useState({
        landingPage: true,
        currentTab: 'ORH',
        firstName : '',
        landingPage: true,
        inputData: {
            first_name: '',
            middle_name: '',
            last_name: '',
            ssn: '',
            dob: '',
            state: '',
        },
    });    

    function loadResearchVars(notificationData){
        setData(notificationData);
    }

    const functions = {
        setInputData,
    }

    const researchVars = {
        data,
        setData,
        functions
    }

    return researchVars;
};

export default ResearchVars;