import React, { useEffect, useState } from 'react';
import '../../CSS/UploadOverlay.css';

import DownloadIcon from '@mui/icons-material/Download';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import CircularProgress from '@mui/material/CircularProgress';

function UploadHistory(props){
    const session = props?.session;
    const viewType = session?.env?.viewport?.viewType;
    const reformatDate = session?.env?.functions?.reformatDate;
    const historyData = session?.upload?.data?.historyData;
    const historyLoading = session?.upload?.data?.historyLoading;
    const accountNumber = session?.upload?.data?.accountNumber;
    const updateHistory = historyData?.[accountNumber]?.updateHistory?.slice().reverse();

    const fileUploadHistory = updateHistory?.filter(item => /\.(xlsx|xls|csv)$/i.test(item.file_name));

    const singleUploadHistory = updateHistory?.filter(item => !/\.(xlsx|xls|csv)$/i.test(item.file_name));

    const dataRowsCount = fileUploadHistory?.length || 0;
    const singleDataRowsCount = singleUploadHistory?.length || 0;
    const [errorStatus, setErrorStatus] = useState();
    const fileTabSelected = session?.upload?.data?.fileTabSelected;
    
    function calculateTotalErrorCount(currentUpload){
        let errorCount = 0;

        errorCount += currentUpload?.first_name;
        errorCount += currentUpload?.last_name;
        errorCount += currentUpload?.ssn;
        errorCount += currentUpload?.dob;
        // errorCount += currentUpload?.city;
        // errorCount += currentUpload?.state; 
        errorCount += currentUpload?.zip_code;

        return errorCount;
    }

    const uploads = [
        { filename: "UploadTemplate.xlsx", date: "01/23/24", number1: "7500", number2: "37" },
        { filename: "UploadTemplate2.xlsx", date: "01/12/24", number1: "8000", number2: "40" },
        { filename: "UploadTemplate3.xlsx", date: "11/17/23", number1: "9", number2: "0" },
        { filename: "UploadTemplate4.xlsx", date: "10/02/23", number1: "430000", number2: "8900" },
        { filename: "UploadTemplate5.xlsx", date: "9/28/23", number1: "2600", number2: "210" },
        { filename: "UploadTemplate.xlsx", date: "01/23/24", number1: "7500", number2: "37" },
        { filename: "UploadTemplate2.xlsx", date: "01/12/24", number1: "8000", number2: "40" },
        { filename: "UploadTemplate3.xlsx", date: "11/17/23", number1: "9", number2: "0" },
        { filename: "UploadTemplate4.xlsx", date: "10/02/23", number1: "430000", number2: "8900" },
        { filename: "UploadTemplate5.xlsx", date: "9/28/23", number1: "2600", number2: "210" },
    ];

    function convertISOToMMDDYYYY(isoDateString) {
        const date = new Date(isoDateString);
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months start at 0!
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${mm}/${dd}/${yyyy}`;
    }

    function printOverlayContent() {
         // Create a copy and reverse the order
        const totalRowsNeeded = props?.overlay === 'uploadSuccess' ? 7 : 7;
        
        let emptyRowsCount = dataRowsCount < totalRowsNeeded ? totalRowsNeeded - dataRowsCount : 0;

        if (historyLoading) {
            emptyRowsCount = totalRowsNeeded;
            return (
                <div className={`${props?.overlay === 'uploadSuccess' ? 'successHistoryContainer' : 'historyContainer'} g bR`}>
                    <div className={`${props?.overlay === 'uploadSuccess' ? 'historySuccessHeaders' : 'historyHeaders'} gR1 g f cC`}>
                        <div className='gC1 bold'>File Name</div>
                        {viewType === "full" ? <div className='gC2 bold'>Date</div> : <div className='gC2 bold'>Date</div> }
                        {viewType === "full" ? <div className='gC3 bold'>Individuals</div> : <div className='gC3 bold'>Individuals</div> }
                        <div className='gC4 bold'>{viewType === "mobile" ? "Invalid" : "Invalid Entries"}</div>
                    </div>   
                    
                    {Array.from({ length: emptyRowsCount }, (_, index) => (
                        <div 
                            key={`empty-${index}`} 
                            className={`${(index) % 2 === 0 ? 'tileShade2' : 'tileShade1'} uploadTile g gR${index + 2} cC`} style={{marginBottom:'4px'}}
                        >
                            <div className='gC1'>&nbsp;</div>
                            {index === 3 && <CircularProgress disableShrink color="success" variant="indeterminate" fontSize="large"/> }
                            <div className='gC2'>&nbsp;</div>                
                            <div className='gC3'>&nbsp;</div>
                            <div className='gC4'>&nbsp;</div>
                        </div>
                    ))}
                </div>
            );
        }
        else if (historyData) {
            const displayedUploads = props?.overlay === 'uploadSuccess' ? fileUploadHistory?.slice(1) : fileUploadHistory;
            // Calculate the actual data rows count for display
            const actualDataRowsCount = displayedUploads?.length || 0;
            emptyRowsCount = dataRowsCount < totalRowsNeeded ? totalRowsNeeded - dataRowsCount : 0;
            return (
                <div className={`${props?.overlay === 'uploadSuccess' ? 'successHistoryContainer' : 'historyContainer'} g bR`}>
                    <div className={`${props?.overlay === 'uploadSuccess' ? 'historySuccessHeaders' : 'historyHeaders'} gR1 g f cC`}>
                        <div className='gC1 bold'>File Name</div>
                        {viewType === "full" ? <div className='gC2 bold'>Date</div> : <div className='gC2 bold'>Date</div> }
                        {viewType === "full" ? <div className='gC3 bold'>Individuals</div> : <div className='gC3 bold'>Individuals</div> }
                        <div className='gC4 bold'>{viewType === "mobile" ? "Invalid" : "Invalid Entries"}</div>
                    </div>   
                    {displayedUploads?.map((upload, index) => (
                        <div 
                            key={index} 
                            className={`${index % 2 === 0 ? 'tileShade2' : 'tileShade1'} uploadTile g gR${index + 2} cC`}
                        >
                            <div className='gC1 fileGrid g s'><div className='gC1 gCW'>{upload?.file_name}</div> </div>
                            <div className='gC2'>{reformatDate(upload?.createdAt)}</div>
                            <div className='gC3'>{upload?.total_participants.toLocaleString()}</div>
                            <div className='gC4'>{calculateTotalErrorCount(upload).toLocaleString()}</div>
                        </div>
                    ))}
                    {Array.from({ length: emptyRowsCount }, (_, index) => (
                        <div 
                            key={`empty-${index}`} 
                            className={`${(actualDataRowsCount + index) % 2 === 0 ? 'tileShade2' : 'tileShade1'} uploadTile g gR${actualDataRowsCount + index + 2} cC`}
                        >
                            <div className='gC1'>&nbsp;</div>
                            <div className='gC2'>&nbsp;</div>
                            <div className='gC3'>&nbsp;</div>
                            <div className='gC4'>&nbsp;</div>
                        </div>
                    ))}
                    <div className='dP bold historyCount'>{actualDataRowsCount} {props?.overlay !== 'uploadSuccess' ? "Total Uploads" : "Past Uploads"} </div>
                </div>
            );
        }
        else{
            return (          
                <div className={`${props?.overlay === 'uploadSuccess' ? 'successInfoContainer' : 'historyEmptyContainer'} g bR`}>
                    <div className='historyHeaders gR1 g f cC'>
                        <div className='gC1 bold'>File Name</div>
                        {viewType === "full" ? <div className='gC2 bold'>Date</div> : <div className='gC2 bold'>Date</div> }
                        {viewType === "full" ? <div className='gC3 bold'>Individuals</div> : <div className='gC3 bold'>Individuals</div> }
                        <div className='gC4 bold'>{viewType === "mobile" ? "Invalid" : "Invalid Entries"}</div>
                    </div>   

                    <div className='historyEmptyContent gR2 cC g'>
                        <div className='gR2'><HistoryToggleOffIcon style={{fontSize: 60}}/></div>
                        <div className='gR3'>No Previous Uploads</div>
                    </div>

                </div>
            ); 
        }
    }

    function printSingleOverlayContent() {
        // Create a copy and reverse the order
       const totalRowsNeeded = 7;
       
       let emptyRowsCount = singleDataRowsCount < totalRowsNeeded ? totalRowsNeeded - singleDataRowsCount : 0;

       if (historyLoading) {
           emptyRowsCount = totalRowsNeeded;
           return (
               <div className={'historyContainer g bR'}>
                   <div className={'singleHistoryHeaders gR1 g f cC'}>
                       <div className='gC1 bold'>Name</div>
                       {viewType === "full" ? <div className='gC2 bold'>Date</div> : <div className='gC2 bold'>Date</div> }
                   </div>   
                   
                   {Array.from({ length: emptyRowsCount }, (_, index) => (
                       <div 
                           key={`empty-${index}`} 
                           className={`${(index) % 2 === 0 ? 'tileShade2' : 'tileShade1'} singleUploadTile g gR${index + 2} cC`} style={{marginBottom:'4px'}}
                       >
                           <div className='gC1'>&nbsp;</div>
                           {index === 3 && 
                            <div  style={{ gridColumn: '1 / 3'}}>
                                <CircularProgress disableShrink color="success" variant="indeterminate" fontSize="large"/> 
                            </div>
                           }
                           <div className='gC2'>&nbsp;</div>                
                       </div>
                   ))}
               </div>
           );
       }
       else if (historyData) {
           const displayedUploads = singleUploadHistory;
           // Calculate the actual data rows count for display
           const actualDataRowsCount = displayedUploads?.length || 0;
           emptyRowsCount = actualDataRowsCount < totalRowsNeeded ? totalRowsNeeded - actualDataRowsCount : 0;
           return (
                <div className={'historyContainer g bR'}>
                    <div className={'singleHistoryHeaders gR1 g f cC'}>
                       <div className='gC1 bold'>Name</div>
                       {viewType === "full" ? <div className='gC2 bold'>Date</div> : <div className='gC2 bold'>Date</div> }
                   </div>   
                   {displayedUploads?.map((upload, index) => (
                       <div 
                           key={index} 
                           className={`${index % 2 === 0 ? 'tileShade2' : 'tileShade1'} singleUploadTile g gR${index + 2} cC`}
                       >
                           <div className='gC1 fileGrid g s'><div className='gC1 gCW'>{upload?.file_name}</div> </div>
                           <div className='gC2'>{reformatDate(upload?.createdAt)}</div>
                       </div>
                   ))}
                   {Array.from({ length: emptyRowsCount }, (_, index) => (
                       <div 
                           key={`empty-${index}`} 
                           className={`${(actualDataRowsCount + index) % 2 === 0 ? 'tileShade2' : 'tileShade1'} singleUploadTile g gR${actualDataRowsCount + index + 2} cC`}
                       >
                           <div className='gC1'>&nbsp;</div>
                           <div className='gC2'>&nbsp;</div>
                       </div>
                   ))}
                   <div className='dP bold historyCount'>{actualDataRowsCount} {props?.overlay !== 'uploadSuccess' ? "Total Uploads" : "Past Uploads"} </div>
               </div>
           );
       }
       else{
           return (          
                <div className={'historyEmptyContainer g bR'}>
                   <div className={'singleHistoryHeaders gR1 g f cC'}>
                       <div className='gC1 bold'>Name</div>
                       {viewType === "full" ? <div className='gC2 bold'>Date</div> : <div className='gC2 bold'>Date</div> }
                   </div>   

                   <div className='historyEmptyContent gR2 cC g'>
                       <div className='gR2'><HistoryToggleOffIcon style={{fontSize: 60}}/></div>
                       <div className='gR3'>No Previous Uploads</div>
                   </div>
               </div>
           ); 
       }
   }

    return (
        <>
            <div className="overlayBoard g f fC oH">     
                    {fileTabSelected === 'many' ?
                        printOverlayContent() 
                        :
                        printSingleOverlayContent() 
                    }            
            </div>    
        </>
    );
};

export default UploadHistory;